<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-tabs">
                    <div class="p-0 card-header border-bottom-0">
                        <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                            <li class="px-3 pt-2"><h3 class="card-title">{{ producer.name}}</h3></li>
                            <li class="nav-item">
                                <a href="#custom-tabs-four-general" class="nav-link active" id="custom-tabs-four-general-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-general" aria-selected="true">Allgemein</a>
                            </li>
                            <li class="nav-item">
                                <a href="#custom-tabs-four-articles" class="nav-link" id="custom-tabs-four-articles-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-articles" aria-selected="true">Artikel</a>
                            </li>
                            <!-- <li class="nav-item">
                                <a href="#custom-tabs-four-contacts" class="nav-link" id="custom-tabs-four-contacts-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-contacts" aria-selected="false">Kontakte</a>
                            </li> -->
                            <!-- <li class="nav-item">
                                <a href="#custom-tabs-four-order" class="nav-link" id="custom-tabs-four-order-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-order" aria-selected="false">Bestellinformationen</a>
                            </li> -->
                            <!-- <li class="nav-item">
                                <a href="#custom-tabs-four-distributors" class="nav-link" id="custom-tabs-four-distributors-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-distributors" aria-selected="false">Lieferanten</a>
                            </li> -->
                            <li class="nav-item">
                                <a href="#custom-tabs-four-meta" class="nav-link" id="custom-tabs-four-meta-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-meta" aria-selected="false">Meta</a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="tab-content" id="custom-tabs-four-tabContent">
                            <div class="tab-pane fade active show" id="custom-tabs-four-general" role="tabpanel" aria-labelledby="custom-tabs-four-general">
                                <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <!-- <div class="row">
                                                <div class="col-md-8">
                                                    <div class="form-group">
                                                        <label for="company_id" class="control-label">Shop*</label>
                                                        <select v-model="form.company_id" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('company_id')}" :disabled="true">
                                                            <option v-for="company in companies" :key="company.id" :value="company.id">{{company.name}}</option>
                                                        </select>
                                                        <has-error :form="form" field="company_id"></has-error>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="active" class="control-label">Online anzeigen?*</label>
                                                        <select v-model="form.active" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('active')}">
                                                            <option value="1">Ja</option>
                                                            <option value="0">Nein</option>
                                                        </select>
                                                        <has-error :form="form" field="active"></has-error>
                                                    </div>
                                                </div>
                                            </div> -->
                                            

                                            <div class="form-group" v-if="producer.thumbnail != ''">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label for="name" class="control-label mr-5">Name*</label>
                                                            <!-- <label class="form-label">Keine Weiterleitung?</label> <input type="checkbox" v-model="form.editSlug" class="ml-2 form-check-input"> -->
                                                            <input v-model="form.name" name="name" id="name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}">
                                                            <has-error :form="form" field="name"></has-error>
                                                        </div>
                                                        <div class="form-group" style="color: #499360;">
                                                            <label for="display_name" class="control-label">
                                                                Anzeige-Name
                                                                <i class="fas fa-question-circle" id="tooltip-displayname"></i>
                                                                <b-tooltip target="tooltip-displayname" triggers="hover">
                                                                    Wenn ein Name in das Feld eingetragen wird, wird dieser in der Front auf Produktebene angezeigt (Artikelübersicht, Artikeldetailansicht...). 
                                                                    In der Herstellerübersicht wird der richtige Name angezeigt. Das Feld kann also auch leer bleiben.
                                                                </b-tooltip>  
                                                            </label>
                                                            <input v-model="form.display_name" name="display_name" id="display_name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('display_name')}">
                                                            <has-error :form="form" field="display_name"></has-error>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label for="number" class="control-label">Händlernummer*</label>
                                                        <input v-model="form.number" name="number" id="number" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('number')}">
                                                        <has-error :form="form" field="number"></has-error>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <figure class="float-right mt-2" style="width: 70%; height: auto;">
                                                            <img v-bind:src="producer.thumbnail" id="brandLogo" class="img-fluid" alt="img">
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row" v-if="producer.thumbnail == ''">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="name" class="control-label">Name*</label>
                                                        <input v-model="form.name" name="name" id="name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}">
                                                        <has-error :form="form" field="name"></has-error>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="number" class="control-label">Herstellernummer*</label>
                                                        <input v-model="form.number" name="number" id="number" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('number')}">
                                                        <has-error :form="form" field="number"></has-error>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="display_name" class="control-label">Anzeige-Name</label>
                                                        <input v-model="form.display_name" name="display_name" id="display_name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('display_name')}">
                                                        <has-error :form="form" field="display_name"></has-error>
                                                    </div>
                                                </div>
                                            </div>

                                            

                                            <div class="form-group">
                                                <label for="name" class="control-label">Beschreibung</label>
                                                <ckeditor :editor="editor" :config="editorConfig" v-model="form.description"></ckeditor>
                                                <has-error :form="form" field="description"></has-error>
                                            </div>

                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-10">
                                                        <div class="form-group">
                                                            <label for="street" class="control-label">Straße*</label>
                                                            <input v-model="form.street" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('street')}">
                                                            <has-error :form="form" field="street"></has-error>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <label for="house_number" class="control-label">Nr.*</label>
                                                            <input v-model="form.house_number" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('house_number')}">
                                                            <has-error :form="form" field="house_number"></has-error>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <label for="zipcode" class="control-label">PLZ*</label>
                                                        <input v-model="form.zipcode" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('zipcode')}">
                                                        <has-error :form="form" field="zipcode"></has-error>
                                                    </div>

                                                    <div class="col-md-8">
                                                        <label for="city" class="control-label">Ort*</label>
                                                        <input v-model="form.city" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('city')}" >
                                                        <has-error :form="form" field="city"></has-error>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label for="logo" class="control-label">Logo</label>
                                                <b-form-file id="logo" size="sm" class="form-control" v-model="form.logo" :class="{'is-invalid': form.errors.has('logo')}" />
                                                <has-error :form="form" field="logo"></has-error>
                                            </div>

                                            <div class="form-group">
                                                <label for="companies" class="control-label">Online Sichtbar</label>
                                                <!-- <b-form-file id="logo" size="sm" class="form-control" v-model="form.logo" :class="{'is-invalid': form.errors.has('logo')}" />
                                                <has-error :form="form" field="logo"></has-error> -->
                                                <table class="table table-striped">
                                                    <thead>
                                                        <th scope="col">Firma</th>
                                                        <th scope="col">Online sichtbar</th>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="company in producer.companies" :key="company.id">
                                                            <td>{{ company.name }}</td>
                                                            <td>
                                                                <select class="form-control form-control-sm" v-model="company.pivot.active">
                                                                    <option value="1">Ja</option>
                                                                    <option value="0">Nein</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>


                                            <!-- <div class="form-group">
                                                <label for="notice" class="control-label">Notizen</label>
                                                <ckeditor :editor="editor" :config="editorConfig" v-model="form.notice" class="form-control"></ckeditor>
                                                <has-error :form="form" field="notice"></has-error>
                                            </div> -->

                                            <div class="form-group" v-if="$auth.check('producers.edit')">
                                                <b-button size="sm" variant="success" @click="editProducer">Speichern</b-button>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <GmapMap
                                            :center="{lat:latitude, lng:longitude}"
                                            :zoom="15"
                                            map-type-id="terrain"
                                            style="width: 100%; height: 100%"
                                            >
                                                <GmapMarker
                                                    :position="google && new google.maps.LatLng(producer.longitude, producer.latitude)"
                                                />
                                            </GmapMap>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="tab-pane fade" id="custom-tabs-four-articles" role="tabpanel" aria-labelledby="custom-tabs-four-articles">
                                <Articles :articles="articles" v-if="notEmptyObject(producer)" />
                            </div>
                            <!-- <div class="tab-pane fade" id="custom-tabs-four-contacts" role="tabpanel" aria-labelledby="custom-tabs-four-contacts">
                                <Contacts :contacts="contacts" v-if="notEmptyObject(producer)" />
                            </div> -->
                            <!-- <div class="tab-pane fade" id="custom-tabs-four-order" role="tabpanel" aria-labelledby="custom-tabs-four-order">
                                <OrderInfos :producer="producer" v-if="notEmptyObject(producer)"/>
                            </div> -->
                            <!-- <div class="tab-pane fade" id="custom-tabs-four-distributors" role="tabpanel" aria-labelledby="custom-tabs-four-distributors">
                                <Distributors :distributors="distributors" v-if="notEmptyObject(producer)"/>
                            </div> -->
                            <div class="tab-pane fade" id="custom-tabs-four-meta" role="tabpanel" aria-labelledby="custom-tabs-four-meta">
                                <Meta :producer="producer" v-if="notEmptyObject(producer)"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
import { gmapApi } from 'gmap-vue';
import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';


import Articles from './components/Articles';
// import Contacts from './components/Contacts';
// import OrderInfos from './components/OrderInfos';
// import Distributors from './components/Distributors';
import Meta from './components/Meta';

export default {
    name: "ProducerDetails",
    title: "Herstellerdetails",
    components:{
        Articles,
        Meta
        // Contacts,
        // OrderInfos,
        // Distributors,
    },

    data() {
        return {
            url: process.env.VUE_APP_API_URL,
            form: new window.Form({
                id: "",
                // company_id: "",
                name: "",
                description: "",
                street: "",
                house_number: "",
                zipcode: "",
                city: "",
                logo: [],
                // notice: "",
                number: "",
                // active: "",
                companies: "",
                _method: "PUT",
                display_name: "",
                editSlug: false,
            }),
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }

            },
            articles: [],
            contacts: [],
            companies: [],
            distributors: [],
            producer: {},
            editMode: false,
            latitude: 0,
            longitude: 0,
            enabled: [],
        }
    }, 

    methods:{

        editModeOn() {
            this.editMode = true;
        },

        editModeOff() {
            this.form.reset();
            this.form.fill(this.producer);
            this.form._method ="PUT";
            this.editMode = false;
        },

        

        editProducer() {
            this.enabled = [];
            this.producer.companies.forEach(function (item){
                this.enabled.push({
                    id: item.id,
                    active: item.pivot.active
                });
            },this);
            this.form.companies = '';
            this.form.companies = JSON.stringify(this.enabled);
            delete this.form.editSlug;

            this.form
                .post("/producers/"+this.producer.id)
                .then(() => {
                    this.loadProducer();
                    this.editModeOff();
                    this.$swal({
                        icon: "success",
                        title: "Hersteller wurde editiert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        },

        loadProducer() {
            this.axios
                .get("/producers/" + this.$route.params.id)
                .then((response) => {
                    this.producer = response.data.data;
                    this.articles = this.producer.articles;
                    this.contacts = this.producer.contacts;
                    this.distributors = this.producer.distributors;
                    if(this.producer.notice == null)
                    {
                        this.producer.notice = "";
                    }
                    if(this.producer.description == null)
                    {
                        this.producer.description = "";
                    }
                    if(this.producer.orderinfo == null)
                    {
                        this.producer.orderinfo = "";
                    }
                    this.form.fill(this.producer);
                    this.form.editSlug = false;
                    this.form._method = "PUT";
                    this.latitude = parseFloat(this.producer.longitude);
                    this.longitude = parseFloat(this.producer.latitude);

                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadCompanies() {
            this.$Progress.start();
            this.axios
                .get("/companies")
                .then((response) => {
                    this.companies = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    created() {
        this.loadProducer();
        this.loadCompanies();
    },

    computed: {

        google: gmapApi,

    },
}
</script>

<style>

</style>