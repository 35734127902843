<template>
    <div>
        <fieldset class="p-2 mt-3 border">
            <legend class="w-auto">SEO</legend>
            <div class="row">
                <div class="text-right col-md-12">
                    <b-button class="mt-2" size="sm" @click="saveSeo()" variant="primary"><i class="fas fa-save"></i> SEO speichern</b-button>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Meta Title</label>
                        <input type="text" v-model="seo.meta_title" class="form-control" :class="{'is-invalid': seo.errors.has('meta_title')}" />
                        <has-error :form="seo" field="meta_title"></has-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Meta Keywords</label>
                        <ckeditor :editor="editor" :config="editorConfig" v-model="seo.meta_keywords" :class="{'is-invalid': seo.errors.has('meta_keywords')}"></ckeditor>
                        <has-error :form="seo" field="meta_keywords"></has-error>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Meta Description</label>
                        <ckeditor :editor="editor" :config="editorConfig" v-model="seo.meta_description" :class="{'is-invalid': seo.errors.has('meta_description')}"></ckeditor>
                        <has-error :form="seo" field="meta_description"></has-error>
                    </div>
                </div>
            </div>

        </fieldset>
    </div>
</template>

<script>
import ClassicEditor from './../../../assets/packages/ckeditor5/src/ckeditor';
export default {

    props: [
        'producer',
    ],

    data() {
        return {
                seo: new window.Form({
                            'meta_description': '',
                            'meta_keywords': '',
                            'meta_title': '',
                        }),
                
                editor: ClassicEditor,
                editorConfig: {
                    simpleUpload: {
                        uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                        withCredentials: true,
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer '+ this.$auth.token()
                        }
                    }
                },
        }
    },

    methods:{
        saveSeo(){
            this.seo
                .put("/producers/"+this.producer.id)
                .then(() => {
                    this.$parent.loadProducer();
                    this.$swal({
                        icon: "success",
                        title: "Mete Daten gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        }
    },

    created(){
        this.seo.meta_description = this.producer.meta_description;
        this.seo.meta_keywords = this.producer.meta_keywords;
        this.seo.meta_title = this.producer.meta_title;
    }
}
</script>

<style>

</style>